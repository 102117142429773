<template>
  <div class="page">
    <Navbar title="用户绑定" type="SMP" />
    <van-row>
      <van-col span="24">
        <img
          :src="require('../../../assets/images/focus/ttp.jpg')"
          class="img"
        />
      </van-col>
    </van-row>
    <van-row>
      <van-col span="24" class="color-s2 tips"
        >长按上方二维码关注公众号，获取更多福利</van-col
      >
    </van-row>
    <Share ref="share" />
  </div>
</template>
<script>
import Navbar from '../common/Navbar.vue'
import Share from '../common/Share.vue'
export default {
  components: {
    Navbar: Navbar,
    Share: Share
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.tips {
  font-size: 14px;
  font-weight: 600;
}
.img {
  width: 80%;
}
</style>
